@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #ff8c00;
  --secondary: #c3ff00;
  --sb-thumb-color: var(--primary);
  --sb-track-color: #232e33;
  --sb-size: 14px;
}

body {
  margin: 0;
  font-family: poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-black;
  @apply text-white;
}

.bg-header {
  background-color: #1e2022;
}

.bg-animate-barberpole {
  background-size: 30px 30px;
  background-image: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.1) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0.1) 75%,
    transparent 75%,
    transparent
  );
  animation: barberpole 1s linear infinite;
}

@keyframes barberpole {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 30px 30px;
  }
}

.btn {
  @apply p-2;
  @apply px-2;
  @apply rounded-md;
  @apply justify-center;
  @apply text-center;
  @apply font-bold;
  @apply bg-hoa-orange;
  @apply text-white;
  @apply disabled:text-gray-800;
  @apply disabled:bg-gray-600;
}

.input {
  @apply p-1;
  @apply rounded-md;
  @apply border-hoa-orange;
  @apply text-black;
  @apply disabled:text-gray-800;
  @apply disabled:bg-gray-600;
}

hr {
  @apply border-hoa-orange;
}

.label {
  @apply font-normal;
}

.value {
  @apply font-bold;
}

.title-xl {
  @apply text-hoa-orange;
  @apply text-xl;
  @apply font-bold;
}

.panel {
  @apply border-2;
  @apply p-4;
  @apply rounded-md;
  @apply border-hoa-orange;
}

.popup {
  @apply bg-orange-950;
  @apply text-white;
  @apply panel;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
}

.ReactModal__Content {
  margin: auto;
}

.flex-row-valign {
  @apply flex;
  @apply flex-row;
  @apply items-center;
  @apply gap-2;
}

*::-webkit-scrollbar {
  width: var(--sb-size);
}

*::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 0.375rem;
}

*::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 0.275rem;
  border: 2px solid #000;
}

@supports not selector(::-webkit-scrollbar) {
  * {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}
